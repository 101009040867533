.disabledRow {
  td {
    opacity: 0.5;
    background-color: #d9d9d9;
  }
  .statusSelector {
    opacity: 1;
  }
}

.source {
  overflow: hidden;
}
