.container {
  padding-top: 24px;
  padding-right: 24px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  .instruction {
    padding-left: 2em;
  }
}

.locked {
  background-color: #f5f5f5;

  > * {
    color: #bcbcbc;
  }
}

.smart {
  background-color: #e6f8ff;
}
