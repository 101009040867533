.container {
  height: 100%;
  padding-bottom: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
}

.player {
  height: calc(100% - 65px);
  min-height: calc(100% - 65px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
}

.closeButton {
  background-color: #ffffff;
  padding: 4px 15px 4px 15px;
}
