.container {
  padding: 24px;
  display: flex;
  flex-flow: row wrap;
  gap: 48px;

  justify-content: space-between;

  &::after {
    content: "";
    flex: auto;
  }
}
