.actionsContainer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: -webkit-linear-gradient(
    left,
    rgba(250, 250, 250, 0.1) 0%,
    rgba(250, 250, 250, 0.3) 5%,
    rgba(250, 250, 250, 0.6) 20%,
    rgba(250, 250, 250, 0.6) 30%,
    rgba(250, 250, 250, 0.6) 80%,
    rgba(250, 250, 250, 0.65) 100%
  );
  padding-right: 12px;

  li {
    pointer-events: all;
    margin: 0 4px;
    list-style: none;
  }
}
