.layerName {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 2em;
}

.container {
  width: 100%;
  background-color: #f5f5f5;
  padding: 1em 15px;

  display: flex;
  flex-direction: column;
  gap: 1em;

  .field {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1em;

    .condition {
      width: 100%;

      display: flex;
      gap: 0.5em;
      align-items: center;

      > *:nth-child(1) {
        flex: initial;
        width: 4em;
      }

      > * {
        flex: 2;
      }

      > .op {
        flex: 1;
      }

      .actions {
        flex: initial;

        width: 4.5em;
        display: flex;
        justify-content: flex-start;
        > * {
          flex: initial;
          width: 2em;
        }
      }
    }

    .show {
      > *:nth-child(1) {
        flex: initial;
        width: 4em;
      }

      display: flex;
      gap: 0.5em;
      align-items: center;
    }
  }

  .else {
    > *:nth-child(1) {
      flex: initial;
      width: 3em;
    }
    display: flex;
    gap: 0.5em;
    align-items: center;
  }
}

.label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.7rem;
}

.edit {
  width: 5em;
}

.editPen {
  margin-right: 5px;
  path {
    fill: #1890ff;
  }
}

.addCondition {
  width: 11em;
  margin: 1em 0;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}

.gray {
  color: #7f888b;
}
