.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .icon {
    font-size: 55px;
    color: #b8b8b8;
  }

  .title {
    font-size: 20px;
    color: #b8b8b8;
  }

  .preview {
    width: 100%;
    height: 100%;
    padding: 24px;
  }
}
