.selection {
  width: 100%;
  display: flex;
  gap: 0.5em;

  > *:nth-child(1) {
    flex: 1;
  }

  > *:nth-child(2) {
    flex: 2;
  }
}
