.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;

  > * {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .pages {
    flex: initial;
    width: 150px;

    padding-right: 15px;

    .page {
      width: 100%;
      height: 200px;

      margin-bottom: 10px;

      background-color: white;

      &.selected {
        border: 2px solid #1990ff;
      }
    }
  }

  .canvasContainer {
    flex: 2;
    height: 100%;

    > * {
      width: 80%;
      height: 600px;
      background-color: white;
      margin: 0 auto;
      margin-bottom: 50px;
    }
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
