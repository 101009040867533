.container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .children {
    margin-left: 2em;
  }

  .options {
    margin-left: 2em;

    .title {
      display: flex;
      gap: 0.5em;
      align-items: center;
      margin-top: 0.5em;
    }

    .optionList {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
    }

    .option {
      display: flex;
      align-items: center;
      gap: 0.5em;

      > *:last-child {
        flex: initial;
        width: 2em;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
