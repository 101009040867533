.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  .tabsContainer {
    flex: initial;
    width: 600px;

    .tabs {
      width: 100%;
    }
  }

  .previewContainer {
    flex: 1;
    background-color: #f5f5f5;
  }

  .content {
    width: 100%;
    height: 100%;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1em;
}

.tabs {
  width: 100%;
  height: 100%;
  .ant-tabs-content {
    height: 100%;
  }

  .tabPane {
    height: 100%;
  }
}
