.container {
  padding-top: 24px;
  padding-right: 24px;

  display: flex;
  flex-direction: column;
  gap: 1em;

  .uploadDraggerContainer {
    flex: initial;
  }

  .formContainer {
    flex: 1;
  }
}
