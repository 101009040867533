.media {
  width: 100%;
  height: 100%;
}

.previewContainer {
  width: 100%;

  flex: 1;
  margin-top: 2em;

  display: flex;
  flex-direction: column;

  overflow: auto;
}
